import React from "react";
import Container from 'react-bootstrap/Container';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const popover1 = (
  <Popover>
    <Popover.Body className="p-3 font-link">
      Apthypication is a new type of brand, the purpose is to create a clothing brand both in
      the real life and the metaverse. Being part of the culture is undeniable.
    </Popover.Body>
  </Popover>
);

const popover2 = (
  <Popover>
    <Popover.Body className="p-3 font-link">
      It’s 5000 unique Apthypic NFT who gonna be out (+ 5000 airdrop for holders).
    </Popover.Body>
  </Popover>
);

const popover3 = (
  <Popover>
    <Popover.Body className="p-3 font-link">
    NO, they gonna be free for those who have NFT from the first 5000.
    </Popover.Body>
  </Popover>
);


const popover4 = (
  <Popover>
    <Popover.Body className="p-3 font-link">
    Few step are to follow :
    First you need to get some ETH (Ethereum) into a wallet (MetaMask for example)
    for that u have to buy some from a crypto exchange
    After getting ETH in your wallet you gonna have to connect it to the mint website (this one
    gonna be communicate at the moment) and click on the button « mint »
    Join our Discord to see the step to do, a tutorial is here.
    </Popover.Body>
  </Popover>
);

const popover5 = (
  <Popover>
    <Popover.Body className="p-3 font-link">
    One per wallet.
    </Popover.Body>
  </Popover>
);

const popover6 = (
  <Popover>
    <Popover.Body className="p-3 font-link" >
    Put your Apthypic into staking give you access to the benefits of the project, such as
    receive free cloth at each drop (4 a year), airdrop of the second part of the collection (with
    new benefits, those are for life), airdrop of the clothing box for your avatar in the
    metaverse, a fashion show for holder only.
    </Popover.Body>
  </Popover>
);

const popover7 = (
  <Popover>
    <Popover.Body className="p-3 font-link">
    To do it you have to unstake your Apthypic and put it into the secondary market (as
    Opensea). But be careful, if you unstake your NFT, you lose your cumulate benefits.
    </Popover.Body>
  </Popover>
);

const popover8 = (
  <Popover>
    <Popover.Body className="p-3 font-link">
    The reveal for your Apthypic will happen 2 weeks after the sale ended.
    </Popover.Body>
  </Popover>
);

export default function Faqs() {

  return (

      <Container className="page text-white font-link" size="lg">
        <h1 className="header pb-5">FAQs</h1>
        <div className="d-grid gap-3 text-white font-link">

          <OverlayTrigger trigger="hover" placement="auto-start" overlay={popover1}>
            <Button className="p-3" style={{fontSize: 20}}variant="outline-light">What is Apthypication ?</Button>
          </OverlayTrigger>

          <OverlayTrigger trigger="hover" placement="auto-start" overlay={popover2}>
            <Button className="p-3" style={{fontSize: 20}} variant="outline-light">How many NFT will be available ?</Button>
          </OverlayTrigger>

          <OverlayTrigger trigger="hover" placement="auto-start" overlay={popover3}>
            <Button  className="p-3"style={{fontSize: 20}} variant="outline-light">The 5000 other will be charged ?</Button>
          </OverlayTrigger>

          <OverlayTrigger trigger="hover" placement="auto-start" overlay={popover4}>
            <Button className="p-3" style={{fontSize: 20}} variant="outline-light">How can I mint an Apthypication NFT ?</Button>
          </OverlayTrigger>

          <OverlayTrigger trigger="hover" placement="auto-start" overlay={popover5}>
            <Button className="p-3" style={{fontSize: 20}} variant="outline-light">How many Apthypication NFT can I mint ?</Button>
          </OverlayTrigger>

          <OverlayTrigger trigger="hover" placement="auto-start" overlay={popover6}>
            <Button  className="p-3" style={{fontSize: 20}} variant="outline-light">What are the reward of staking ?</Button>
          </OverlayTrigger>

          <OverlayTrigger trigger="hover" placement="auto-start" overlay={popover7}>
            <Button className="p-3" style={{fontSize: 20}} variant="outline-light">Can i put my Apthypication NFT in sell during staking ?</Button>
          </OverlayTrigger>

          <OverlayTrigger trigger="hover" placement="auto-start" overlay={popover8}>
            <Button className="p-3" style={{fontSize: 20}} variant="outline-light">When is the reveal ?</Button>
          </OverlayTrigger>

        </div>

  </Container>

  );

}