import React from 'react';

import Container from 'react-bootstrap/Container';


import Home from './Home';
import About from './About';
import Story from './Story';
import Promise from './Promise';
import Staking from './Staking';
import Team from './Team';
import Faqs from './Faqs';
import Footer from './Footer';

export default function Main(){

  return (

    <div>
      <div id="home"><Home/></div>
      <div id="about"><About/></div>
      <div id="story"><Story/></div>
      <div id="promise"><Promise/></div>
      <div id="staking"><Staking/></div>
      <div id="team"><Team/></div>
      <div id="faqs"><Faqs/></div>
      <div id="footer"><Footer/></div>
    </div>

  );

}