import React from "react";
import Container from 'react-bootstrap/Container';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';

export default function Promise() {

  return (

        <Container className="page-promise text-center text-white font-promise">
          <h1 className="header  pb-5">Roadmap</h1>
          <CardGroup>
            <Card className="p-1 bg-dark" border="light">
              <Card.Body>
                <Card.Title className="pb-3">Phase 1</Card.Title>
                <Card.Text>
                  <p>Build our community with passion & vibe</p>
                  <p>5000 NFTs are launched into the movement</p>
                  <p></p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="p-1 bg-dark" border="light">
              <Card.Body>
                <Card.Title className="pb-3">Phase 2</Card.Title>
                <Card.Text>
                  Staking available
                </Card.Text>
                <Card.Text>
                  Production of the first drop
                </Card.Text>
                <Card.Text>
                Implementation of the DAO for certain future choices
                </Card.Text>
                <Card.Text>
                Sending 10% of the funds to a charity
                </Card.Text>
                <Card.Text>
                Making customize outfit for the top3 rarity
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className=" p-1 bg-dark" border="light">
              <Card.Body>
                <Card.Title className="pb-3">Phase 3</Card.Title>
                <Card.Text>
                Sending of your outfits
                </Card.Text>
                <Card.Text>
                Airdrop part 2 of the collection
                </Card.Text>
                <Card.Text>
                Airdrop of the clothing box for metaverse
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="p-1 bg-dark" border="light">
              <Card.Body>
                <Card.Title className="pb-3">Phase 4</Card.Title>
                <Card.Text>
                Being part of the culture, fashion show in real life
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
      </Container>

  );

}