import React from "react";
import { useEffect } from "react";

import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';

import { motion, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";

const boxVariant = {
  visible: { opacity: 1, transition:{duration: 2} },
  hidden: { opacity: 0, scale: 1},
}


export default function Home() {

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (

      <Container className="page header text-white font-link">
        <div className="bg-ange" ></div>
        <Container className="page bg-arthur">
          <h1 class="display-1">Apthypication</h1>
          <motion.div className="box"
            ref={ref}
            variants={boxVariant}
            initial="hidden"
            animate={control}
          >
          <h1 class="display-6">We're apthypic, are you ?</h1>
          </motion.div>
        </Container>
      </Container>
  );

}