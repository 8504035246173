import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

export default function Staking() {

  return (

    <Container className="page-promise bg-lady text-center text-white font-staking">
      
      <Card className="p-5" border="light" style={{ backgroundColor: "transparent"  }}>
      <CardGroup className="p-5">


            <Card style={{ backgroundColor: "transparent"  }} className="p-2" border="dark">
              <Card.Body>
                <Card.Title className="header">Own your NFT</Card.Title>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: "transparent"  }} className="p-2" border="dark">
              <Card.Body>
                <Card.Title className="header">&rarr;</Card.Title>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: "transparent" }} className="p-2" border="dark">
              <Card.Body>
                <Card.Title className="header">Put in staking</Card.Title>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: "transparent"  }} className="p-2" border="dark">
              <Card.Body>
                <Card.Title className="header">&rarr;</Card.Title>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: "transparent"  }} className="p-2" border="dark">
              <Card.Body>
                <Card.Title className="header">Get rewarded</Card.Title>
              </Card.Body>
            </Card>

            </CardGroup>
            </Card>
    </Container>

  );

}