import React, { useRef } from "react";
import { animated, useSpring } from "react-spring";
import { useScroll } from "react-use-gesture";


import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';


import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";



export default function Assets(props){

    const assetsRef = useRef();
    
    
    const scroll = (scrollOffset) => {
        assetsRef.current.scrollLeft += scrollOffset;
    };

    const clamp = (value, clampAt = 30) => {
        if (value > 0) {
        return value > clampAt ? clampAt : value;
        } else {
        return value < -clampAt ? -clampAt : value;
        }
    };

    const [style, set] = useSpring(() => ({
        transform: "perspective(500px) rotateY(0deg)"
    }));

    const bind = useScroll(event => {
        set({ transform: `perspective(500px) rotateY(${event.scrolling ? clamp(event.delta[0]) : 0}deg)` });
    });



    return (

        <div className="pt-5 pb-5">
        <Stack gap={3} direction="horizontal">

            <Button size="lg" className="p-2 bg-dark" variant="transparent" onClick={() => scroll(-200)}><FaArrowAltCircleLeft style={{color: "white"}}/></Button>
            <div className="assets" {...bind()} ref={assetsRef}>
                            
            
                {props.data.map(asset => (
                <div>
                    <animated.div
                        key={asset.tokenId}
                        className="asset"
                        style={{
                        ...style,
                        backgroundImage: `url(${asset.image})`
                        }}
                    >

                    </animated.div>

                </div>
                ))}

            </div>
            <Button size="lg" className="p-2 bg-dark" variant="transparent" onClick={() => scroll(200)}><FaArrowAltCircleRight style={{color: "white"}}/></Button>

        </Stack>
        </div>
    );

}
