import React from "react";
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

export default function Footer() {


  return (
    <Container className="page">
  
        <div className="bg-footer" ></div>

    </Container>
  );

}





