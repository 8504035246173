import React, { useState, useEffect } from "react";

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import Assets from "./Assets";



export default function About() {

  const [initiatorAssets, setInitiatorAssets] = useState([]);
  const [initiatorAssetsIsLoaded, setInitiatorAssetsIsLoaded] = useState(false);
  const [initiatorAssetsIsCharged, setInitiatorAssetsIsCharged] = useState(false);

  const getInitiatorAssets = async () => {

    setInitiatorAssetsIsLoaded(true);

    let newAssets = [];
    for (let i = 1; i < 19; i++ ){

      const asset = { tokenId: i, name: `#${i}`, image: `https://skranf.me/apthypication/${i}.png`}
      newAssets.push(asset);

    }
    setInitiatorAssets(newAssets);
    setInitiatorAssetsIsLoaded(false);
    setInitiatorAssetsIsCharged(true);
  };

  useEffect(() => {
    getInitiatorAssets();
  }, []);


  return (

      <Container className="page-about text-white font-link text-center">


          <h1 className="header pb-5">Bring the NFT space to real world utility</h1>
          <p>
            Having merch in an NFT project is a common thing, in Apthypication it’s different,
            everything turn around that, the goal is to create a real clothing brand, both in the
            metaverse and in the real world. Why being stylish in only one when you can be in both?
          </p>
          <p>
            It's a collection of 5000 unique NFTs (non-fungibletoken)
            on the Ethereum blockchain based on the spirit of the project that gives you access
            to the brand seeing from the inside and its benefits, including clothes, NFT airdrop, irl
            events and some more. Some turns and direction of the project will be voted with the
            community by a DAO.
          </p>
          <p>We are a new type of clothing brand, this is the beginning of a long journey.</p>

          {initiatorAssetsIsLoaded ? <Spinner className="mx-auto d-block" animation="border" variant="light"/> : <></>}
          {initiatorAssetsIsCharged ? <Assets data={initiatorAssets}/> : <></>}
          <Button className="p-3 font-link" variant="outline-light">Go to the marketplace</Button>

      </Container>

  );

}