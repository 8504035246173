import React from "react";

import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';




const Member = (props) => {



  return (


    <Card style={{ backgroundColor: "transparent" }} className="p-4 text-center text-white font-link" border="dark">
      <Card.Img variant="top" src={props.image}/>
      <Card.Body>
        <Card.Title>{props.name}</Card.Title>
        <Card.Subtitle className="text-muted">{props.titre}</Card.Subtitle>
      </Card.Body>
    </Card>


  );

}


export default function Team() {

  return (
    <Container className="page">
          
          <Stack direction="horizontal" gap={1} className="assets">
            <Member name="Apthyp" titre="Founder & Artist" image="https://skranf.me/apthypication/apthyp.png"/>
            <Member name="Skranf" titre="Developer" image="https://skranf.me/apthypication/skranf.png"/>
          </Stack>

    </Container>
  );

}