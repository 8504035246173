import React from "react"

import Container from 'react-bootstrap/Container';

import Menu from './components/Menu';
import Main from './components/Main';

import './App.css';

export default function App(){

  return (

      <Container>

        <Menu/>
        <Main/>
        
      </Container>
      

  );

}