import React from "react";
import Container from 'react-bootstrap/Container';

export default function Story() {


  return (
    <Container className="page-story text-white font-story text-center">
  
        <div className="bg-goddess" ></div>
        <h1 className="header  pb-5">Why doing this ?</h1>
        <p>Apthyp dreams.</p>
        <p>
          I always wanted to give a different look, a different style to my clothes. I’ve been
          customizing my own clothes for 6 years now and I've been creating them for a few months.
          I’ve a lot of design idea. I want through this project to bring you the maximum that I can.
          I launch this project to be able to begin this dream. 
        </p>
        <p>Create a real brand.</p>
        <p >
          I truly believe in my work, my passion.
          I want you to be satisfied with your outfit when you receive them just like when I am
          satisfied with the pieces I create, that will be my greatest accomplishment, that YOU are
          satisfied will make me so. That you wear with pride the clothes I made, that you want to be
          different from the common in the vibe you like. If you are here it's probablybecause we share that
          vibe.
        </p>
        <p>
          We are currently in a world where the digital has gained its place in our lives. With the
          development of different metaverse, this new way of interact allows you to be at two
          opposite place in the world and to be able to exchange, talk, sympathize with others
          people. Even create true relationship. You are satisfied with your clothes, why not make
          your avatar enjoy it too?
          I want to create a community around this passion and support with each other. That
          everyone can help each other and grow together. Our dreams and ambitions have only the
          limits that we set for them, everyone can learn from each other. Be proud of what you do
          and listen to yourself.
        </p>
        <p>
          I intend to give back to those who will allowed me to realize my dream. I mean for the rest
          of my life, you always gonna be in the first place when we gonna pump. It is without
          pretension that I tell you that I see far, I know where I want to go and what I want to create.
        </p>
        <p>
          Join the Apthypication, make your vibe apthypic, as you are.
        </p>

    </Container>
  );

}