import React from "react";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { FaDiscord, FaTwitter } from 'react-icons/fa';


export default function Menu() {

  return (
    <Navbar className="p-4" defaultActiveKey="#home" bg="dark" expand="lg" variant="dark" sticky="top">
        <Container>
            <Navbar.Brand className="p-3 font-logo" href=""><h1>Apthypication</h1></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse className="justify-content-center font-menu" id="navbarScroll">
            <Nav navbarScroll>
                <Nav.Link className="p-3 " style={{ fontSize: 20}} href="#home">Home</Nav.Link>
                <Nav.Link className="p-3" style={{ fontSize: 20}} href="#about">About</Nav.Link>
                <Nav.Link className="p-3" style={{ fontSize: 20}} href="#story">Story</Nav.Link>
                <Nav.Link className="p-3" style={{ fontSize: 20}} href="#promise">Promise</Nav.Link>
                <Nav.Link className="p-3" style={{ fontSize: 20}} href="#staking">Staking</Nav.Link>
                <Nav.Link className="p-3" style={{ fontSize: 20}} href="#team">Team</Nav.Link>
                <Nav.Link className="p-3" style={{ fontSize: 20}} href="#faqs">FAQs</Nav.Link>
            </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end" id="navbarScroll">
            <ButtonGroup size="lg">
                  <Button className="px-2" variant="dark" href="https://google.fr" target="_blank" rel="noreferrer"><FaTwitter/></Button>
                  <Button className="px-2" variant="dark" href="https://google.fr" target="_blank" rel="noreferrer"><FaDiscord/></Button>
            </ButtonGroup>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  );

}












